import Home from "./Home";
import Login from "./pages/Login";

import News from "./pages/News";

import Noun from "./pages/Noun";
import Accountant from "./pages/Accountant";
import Tender from "./pages/Tender";
import Elon from "./pages/Elon";
const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
    routes: [
    
      {
        path: "/home/malumot/uz",
        component: Accountant,
      },
      {
        path: "/home/elon/uz",
        component: Elon,
      },
      {
        path: "/home/fakt/uz",
        component: Noun,
      },
      {
        path: "/home/tender/uz",
        component: Tender,
      },
 
      {
        path: "/home/dashboard/uz",
        component: News,
      },
    
    ],
  },
];

export default routes;
